import React from 'react'

interface Props {
  className?: string
  style?: React.CSSProperties
}
export const Row: React.FC<Props> = (props) => (
  <div
    className={props.className}
    style={{
      display: 'flex',
      flexDirection: 'row',
      ...(props.style || {}),
    }}
  >
    {props.children}
  </div>
)

export const Column: React.FC<Props> = (props) => (
  <div
    className={props.className}
    style={{
      ...(props.style || {}),
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {props.children}
  </div>
)
