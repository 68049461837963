import { useState, useEffect } from 'react'

export const useIsLoggedIn = () => {
  const [value, setValue] = useState(typeof window !== 'undefined' ? !!localStorage.getItem('gtoken') : false)
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    setValue(typeof window !== 'undefined' ? !!localStorage.getItem('gtoken') : false)
  }, [trigger, setValue])

  useEffect(() => {
    setInterval(() => setTrigger((t) => !t), 5000)
  }, [])

  return value
}

export const useLogout = () => {
  return () => {
    typeof window !== 'undefined' && localStorage.removeItem('gtoken')
  }
}

