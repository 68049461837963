import axios from 'axios'

const create = () =>
    axios.create({
        baseURL: '/api',
        headers: {
            gtoken: typeof window !== 'undefined' ? localStorage.getItem('gtoken') : null,
            realmId: typeof window !== 'undefined' ? localStorage.getItem('realmId') : null,
        },
    })

export default create
